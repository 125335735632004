<template>
  <v-container fluid class="text-center">
    <Loader :loading="loading" />
    <Table-Container title="Historial: Préstamos activos" cardTextFooter :exportInfo="items">
      <template v-slot:buttons>
        <Basic-Btn text="Actualizar" color="success" icon="mdi-refresh" @click="refresh()" :loading="loading" />
      </template>
      <template v-slot:search>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
      </template>
      <v-data-table :headers="headers" :items="items" :search="search" :loading="loading" :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }"
      :items-per-page="20" item-key="id" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.nombre_completo`]="{ item }">
          {{ (item.nombre + ' ' + item.apellido_paterno + ' ' + item.apellido_materno).trim() }}
        </template>
        <template v-slot:[`item.monto`]="{ item }">
          {{ '$' + formatNumberToMoney(item.monto) }}
        </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </Table-Container>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </v-container>
</template>

<script>
import { Loader, DialogForm, BasicAlert, TableContainer, BasicBtn } from "@/components"
import services from "@/utils/services"

export default {
  name: "loanRequestHistory",
  components: {
    Loader,
    DialogForm,
    BasicAlert,
    TableContainer,
    BasicBtn,
  },
  data: () => ({
    alert: {
      active: false,
      color: 'error',
      msg: '',
    },
    loading: false,
    search: '',
    headers: [
      { text: "ID", align: "left", sortable: true, value: "idhistorial_solicitud_prestamo" },
      { text: "Tipo", align: "left", sortable: true, value: "tipo" },
      { text: "Grupo", align: "left", sortable: true, value: "grupo" },
      { text: "Fecha", align: "left", sortable: true, value: "fecha" },
      { text: "Cliente", align: "left", sortable: true, value: "cliente" },
      { text: "Nombre", align: "left", sortable: true, value: "nombre_completo" },
      { text: "Calle", align: "left", sortable: true, value: "calle" },
      { text: "Número", align: "left", sortable: true, value: "numero" },
      { text: "Colonia", align: "left", sortable: true, value: "colonia" },
      { text: "Teléfono", align: "left", sortable: true, value: "telefono" },
      { text: "Monto", align: "left", sortable: true, value: "monto" },
      { text: "Clave de elector", align: "left", sortable: true, value: "clave_elector" },
    ],
    items: [],
  }),
  mounted() {
    this.refresh()
  },
  methods: {
    refresh() {
      this.loading = true
      this.axios.get(services.routes.loanRequestHistory)
        .then((response) => {
          this.items = response.data.data
        })
        .catch((error) => {
          this.alert.active = true
          this.alert.color = 'error'
          this.alert.msg = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
        })
    },
    formatNumberToMoney(num) {
      try {
        const aux = Number(num);
        return aux
          .toFixed(2)
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      } catch (e) {
        return ''
      }
    }
  },
};
</script>

<style scoped>
</style>
